$color-neutral-50: #f9f9f9;
$color-neutral-100: #eeefef;
$color-neutral-200: #d4d6d7;
$color-neutral-300: #bfc1c2;
$color-neutral-400: #939596;
$color-neutral-500: #6a6b6c;
$color-neutral-600: #555656;
$color-neutral-700: #404040;
$color-neutral-800: #2a2b2b;
$color-neutral-900: #151515;

$color-primary-30: #fff8f3;
$color-primary-50: #fcf0eb;
$color-primary-100: #f5d2c2;
$color-primary-200: #eba585;
$color-primary-300: #e17b4d;
$color-primary-400: #dd6933;
$color-primary-500: #d44300;
$color-primary-600: #bf3c00;
$color-primary-700: #aa3600;
$color-primary-800: #942f00;
$color-primary-900: #7f2800;

$color-secondary-50: #fff3eb;
$color-secondary-100: #ffdcc3;
$color-secondary-200: #ffd0af;
$color-secondary-300: #ffb987;
$color-secondary-400: #ff9b54;
$color-secondary-500: #ff8a37;
$color-secondary-600: #e67c32;
$color-secondary-700: #cc6e2c;
$color-secondary-800: #b36127;
$color-secondary-900: #995321;

$color-success-50: #ecfdf5;
$color-success-100: #d1fae5;
$color-success-200: #a7f3d0;
$color-success-300: #6ee7b7;
$color-success-400: #34d399;
$color-success-500: #10b981;
$color-success-600: #059669;
$color-success-700: #047857;
$color-success-800: #065f46;
$color-success-900: #064e3b;

$color-warning-50: #fffbeb;
$color-warning-100: #fef3c7;
$color-warning-200: #fde68a;
$color-warning-300: #fcd34d;
$color-warning-400: #fbbf24;
$color-warning-500: #f59e0b;
$color-warning-600: #d97706;
$color-warning-700: #b45309;
$color-warning-800: #92400e;
$color-warning-900: #78350f;

$color-error-50: #fef2f2;
$color-error-100: #fee2e2;
$color-error-200: #fecaca;
$color-error-300: #fca5a5;
$color-error-400: #f87171;
$color-error-500: #ef4444;
$color-error-600: #dc2626;
$color-error-700: #b91c1c;
$color-error-800: #991b1b;
$color-error-900: #7f1d1d;

$color-shades-0: #fff;
$color-shades-100: #000;

$color-property-manager-50: #e9fbff;
$color-property-manager-100: #b2d6e9;
$color-property-manager-150: #0077b6;
